import React, { useState } from 'react'
import Link from 'next/link'

import GlobalStyles from 'components/GlobalStyles'
import MinimalFooter from 'components/MinimalFooter'
import HamburgerIcon from 'icons/Hamburger'
import StoolAndStarsIcon from 'icons/StoolAndStars'
import { THEME } from 'utils'

const ContentBlock = ({ html }) => <div dangerouslySetInnerHTML={{ __html: `<div>${html}</div>` }} />

const EventInfoBox = ({ info_box, event }) => {
  return (
    <>
      <div className='info_box'>
        {info_box?.image && <img className='info_box-image' src={info_box.image} alt={event.name} />}
        <div className='info_box-content'>
          <ContentBlock html={info_box.body} />
        </div>
      </div>
      <style jsx>{`
        .info_box {
          background-color: ${info_box.theme.background};
          border: 1px solid ${info_box.theme.border};
          width: 100%;
          max-width: 1000px;
          width: 100%;
          color: #000;
          margin: ${info_box.position === 'above' ? '0 auto 60px auto' : '60px auto 0 auto'};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;
        }
        .info_box-image {
          display: none;
          max-width: 300px;
          width: 100%;
          margin-right: 15px;
        }
        .info_box-content {
          width: 100%;
        }
        .info_box-content p {
          color: #000;
          font-size: 18px;
          line-height: 1.25;
          display: block;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .info_box-image {
            display: block;
          }
        }
      `}</style>
    </>
  )
}

const EventDetail = ({ event, children, isSubPage = false }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className='page'>
        <header>
          <div className='container'>
            <Link href='/events' legacyBehavior>
              <a className='header__logo'>
                <StoolAndStarsIcon
                  width='40px'
                  height='40px'
                  color={event.theme.header.colorScheme === 'light' ? '#fff' : '#000'}
                />
                <span>Events</span>
              </a>
            </Link>
            <a className='hamburger' onClick={() => setOpen(!open)}>
              <HamburgerIcon width='28px' height='28px' />
            </a>
            <nav className={open ? 'open' : ''}>
              <div className='nav__container'>
                {event.pages.map((page, idx) => (
                  <React.Fragment key={idx}>
                    {page?.slug ? (
                      <Link href={`/events/${event.slug}/${page.slug}`} legacyBehavior>
                        <a>{page.title}</a>
                      </Link>
                    ) : (
                      <a href={page.external_link} target='_blank' rel='noreferrer'>
                        {page.title}
                      </a>
                    )}
                  </React.Fragment>
                ))}
                <Link href='/events' legacyBehavior>
                  <a>All Events</a>
                </Link>
              </div>
            </nav>
          </div>
        </header>
        <section className='hero'>
          <img src={event.images?.header} alt={event.name} />
          <div className='container'>
            {event.images?.logo && <img className='logo' src={event.images.logo} alt={event.name} />}
          </div>
        </section>
        <section className='events'>
          <div className='container'>
            {!isSubPage && event.info_box.render_info_box && event.info_box.position === 'above' && (
              <EventInfoBox info_box={event.info_box} event={event} />
            )}
            {children}
            {!isSubPage && event.info_box.render_info_box && event.info_box.position === 'below' && (
              <EventInfoBox info_box={event.info_box} event={event} />
            )}
          </div>
        </section>
      </div>
      <footer>
        <img src={event.images?.footer} alt={event.name} />
      </footer>
      <MinimalFooter
        linkToggles={{ termsOfUse: true, privacyPolicy: true }}
        foregroundColor={event.theme.footer.colorScheme === 'dark' ? '#000' : '#fff'}
        backgroundColor={event.theme.background.color}
      />
      <GlobalStyles />
      <style jsx>{`
        .page {
          color: white;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          overflow: hidden;
          background-color: ${event.theme?.background?.color ?? '#fff'};
        }
        .events {
          flex: 1;
          padding: 50px 0 40px 0;
          display: flex;
          justify-content: center;
        }
        header {
          padding: 12px 0;
          background: ${event?.theme?.header?.background ?? '#000'};
          width: 100%;
          position: relative;
          z-index: 5;
        }
        header .container {
          max-width: 1030px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .hero {
          width: 100%;
          position: relative;
        }
        footer img {
          width: 100%;
        }
        footer {
          background-color: ${event?.theme?.background?.color ?? '#fff'};
        }
        .header__logo {
          display: flex;
          align-items: center;
          color: #fff;
        }
        .header__logo span {
          font-family: ${THEME.FONTS.BEBAS};
          color: ${event.theme.header.colorScheme === 'light' ? '#fff' : '#000'};
          display: inline-block;
          font-weight: bold;
          margin-left: 8px;
          font-size: 30px;
        }
        .hero .container {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          max-width: 400px;
          padding: 0 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .hero > img {
          display: block;
          height: 100%;
          width: 100%;
        }
        .hero .logo {
          max-width; 100%;
          height: 100%
        }
        nav {
          display: none;
          position: absolute;
          box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px;
          top: 100%;
          left: 0;
          width: 100%;
          background: #fff;
          z-index: 5;
        }
        nav.open {
          display: block;
        }
        .nav__container {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          overflow-x: auto;
        }
        .hamburger {
          cursor: pointer;
          color: #fff;
        }
        nav a {
          color: '#000';
          white-space: nowrap;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          padding: 12px;
          display: block;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .hero > img {
            width: 100%;
          }
          .hero .logo {
            height: auto;
            max-height: 100%;
            max-width: 400px;
          }
        }
      `}</style>
    </>
  )
}

export default EventDetail
