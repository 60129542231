import { observable } from 'mobx'
import { CommonStore } from 'stores/common'
import { eventsApi } from 'api'

export const EventsStore = () => {
  return observable({
    ...CommonStore,
    api: eventsApi
  })
}

EventsStore.cacheKey = 'EventsStore'

export default EventsStore
